<section class="section banner">
	<div [@bannerTrigger] class="container">
		<div class="section-box-banner">
			<div class="content">
				<div>
					<h1>{{ "Banner.Pretitle" | translate }}</h1>
				</div>
				<div class="banner-title">
					<h2>{{ "Banner.Title1" | translate }}</h2>
					<h3>{{ "Banner.Title2" | translate }}</h3>
				</div>
				<div class="banner-description">
					<p [innerHTML]="'Banner.Description' | translate" class="mt-4"></p>
				</div>
			</div> 
			<div class="div-btn-banner"> 
				<a
					(click)="
						analyticsService.sendAnalyticEvent(
							'click_send_mail',
							'banner',
							'email'
						) 
					"
					href="mailto:emir-baycan@hotmail.com"
					target="_black"
					class="main-btn"
				>
					{{ "Banner.ActionBtn" | translate }}
				</a>
			</div>
		</div>
	</div>
</section>
